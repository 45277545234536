









import { Vue, Component } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import {
  getBlog,
  getGlobal,
  getPeople,
  getAwards,
  getCustomers,
  getNetwork,
  getProjects,
  getMethods,
  getBlogcategories,
  getProjectcategories
} from '~/store';
import { StoryblokResponse, StoryblokStory, ApiError, StoryPageDefault, SeoHeader } from '~/types';

@Component({
  components: {}
})

export default class Index extends Vue {
  // ---content
  story: StoryPageDefault | null = null;

  // ---store
  async fetch ({ app, store }: Context) {
    const version: string = app.context.env.NUXT_APP_STORYBLOK_VERSION;
    const api = app.context.$storyapi;
    const language = app.i18n.locale || 'de';
    await getGlobal(store)
      .fetchData({ version, api, language });
    await getBlog(store)
      .fetchData({ version, api, language });
    await getProjects(store)
      .fetchData({ version, api, language });
    await getMethods(store)
      .fetchData({ version, api, language });
    await getPeople(store)
      .fetchData({ version, api, language });
    await getAwards(store)
      .fetchData({ version, api, language });
    await getCustomers(store)
      .fetchData({ version, api, language });
    await getNetwork(store)
      .fetchData({ version, api, language });
    await getBlogcategories(store)
      .fetchData({ version, api, language });
    await getProjectcategories(store)
      .fetchData({ version, api, language });
  }

  // ---content
  async asyncData ({ app, params }: Context) {
    const language = app.i18n.locale || 'de';
    const version: string = app.context.env.NUXT_APP_STORYBLOK_VERSION || 'published';
    const story: StoryblokStory = await app.context.$storyapi
      .get(`cdn/stories/${params.page || 'home'}`, {
        language,
        version
      })
      .then((res: StoryblokResponse) => {
        return res.data.story;
      })
      .catch((err: any) => {
        if (!err.response) {
          const error: ApiError = { statusCode: 404, message: ['Failed to receive content form api'] };
          app.context.error(error);
        } else {
          const error: ApiError = { statusCode: err.response.status, message: err.response.data };
          app.context.error(error);
        }
      });
    return {
      story
    };
  }

  // ---seo
  head () {
    const globalSeo = getGlobal(this.$store).seo as SeoHeader|false;
    if (globalSeo) { globalSeo.fallback = true; }
    const pageSeo = this.story?.content.seo?.length ? this.story.content.seo[0] || false : false;
    if (pageSeo) { pageSeo.fallback = false; }
    const seo = pageSeo || globalSeo || false;
    const storyName: string = this.story ? this.story.content?.title : globalSeo[0]?.title;
    return this.$seoHeader(seo, storyName);
  }

  // ---storybridge
  mounted () {
    if (this.story) {
      this.$initStorybridge(this.$storybridge, this.story);
      Vue.prototype.$eventBus.$on('storyUpdated', this.updateStory);
    }
  }

  beforeDestroy () {
    Vue.prototype.$eventBus.$off('storyUpdated', this.updateStory);
  }

  updateStory (updatedStory: StoryblokStory) {
    this.story = updatedStory;
  }
}
